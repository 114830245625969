import { useMutation } from 'react-query';
import { clickToDial } from '../helpers/external/call';
import { useCallback, useMemo } from 'react';
import { decrypData } from '../util/crypto';
import { toast } from 'react-toastify';
import ClickToCallAlert from '../Components/Operation/Lead/ClickToCall/ClickToCallAlert';
import { useTranslation } from 'react-i18next';
import useBanner from './useBanner';

const useCall = () => {
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'hook.useCall',
	});
	const banner = useBanner();
	const { mutate } = useMutation({
		mutationFn: clickToDial,
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const makeCall = useCallback(
		(customer, phone) => {
			const decryptedData = decrypData(
				localStorage.getItem('authenticatication-crm')
			);
			const user = JSON.parse(decryptedData);
			const data = {
				customer: parseInt(customer),
				phone: phone,
				userName: user.usuario,
				provider: user.provider,
				extension: user.extension,
				server: user.server,
			};
			const isValid =
				Boolean(user.provider) &&
				Boolean(user.extension) &&
				Boolean(user.server);
			if (isValid) {
				toast(<ClickToCallAlert user={user} />, {
					autoClose: 6000,
					position: 'top-center',
				});
				mutate(data);
			} else {
				banner.show('error', tMessage('validateCall'));
			}
		},
		[mutate, banner, tMessage]
	);

	return useMemo(
		() => ({
			makeCall,
		}),
		[makeCall]
	);
};

export default useCall;
