import { useQuery } from 'react-query';
import { useState, useEffect, useMemo } from 'react';
import parseObjectToQueryUrl from '../../../util/parseObjectToQueryUrl';
import { fecthReservation } from '../../../pages/Operation/Reservation/Util/services';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import TableContainer from '../../Common/TableContainer';
import PaginationManual from '../../Common/PaginationManual';
import { useTranslation } from 'react-i18next';
import Loader from '../../Common/Loader';
import moment from 'moment';
import { DATE_FORMAT } from '../../../common/globalsProp';
import { Link } from 'react-router-dom';
import showFriendlyMessafe from '../../../util/showFriendlyMessafe';
import useBanner from '../../../hooks/useBanner';

const ReservationList = ({ filter, enabledQuery }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.reservation.list',
	});
	const banner = useBanner();

	const [queryFilter, setQueryFilter] = useState({
		max: 10,
		page: 1,
	});
	useEffect(() => {
		setQueryFilter({ max: 10, page: 1, ...filter });
	}, [filter]);
	//service
	const {
		data: reservationData,
		error: errorReservationQuery,
		isLoading,
		isSuccess,
		isFetching,
	} = useQuery(
		['getReservationPaginate', queryFilter],
		() => fecthReservation(parseObjectToQueryUrl(queryFilter)),
		{
			keepPreviousData: true,
			enabled: Object.keys(queryFilter).length > 2 && enabledQuery,
		}
	);
	useEffect(() => {
		if (errorReservationQuery?.code) {
			banner.show(
				'error',
				showFriendlyMessafe(errorReservationQuery?.code)
			);
		}
	}, [errorReservationQuery, banner]);

	const columns = useMemo(
		() => [
			{
				Header: 'Id',
				accessor: 'id',
				filterable: false,
				width: '7%',
				Cell: ({ value }) => (
					<Link
						to={`/reservation/${value}`}
						className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
					>
						{value}
					</Link>
				),
			},
			{
				Header: t('confirmation'),
				accessor: 'confirm',
				filterable: false,
				width: '8%',
			},
			{
				Header: 'Booking',
				accessor: 'booking',
				filterable: false,
				width: '7%',
			},
			{
				Header: t('name'),
				accessor: 'customer.firstName',
				filterable: false,
				width: '12%',
				Cell: ({ row, value }) =>
					`${value.toUpperCase()} ${row.original.customer.lastName.toUpperCase()}`,
			},
			{
				Header: t('hotel'),
				accessor: 'hotel.name',
				filterable: false,
				width: '13%',
			},
			{
				Header: t('plan'),
				accessor: 'intPlan.name',
				filterable: false,
				width: '13%',
			},
			{
				Header: t('shortCheckInLabel'),
				accessor: 'initialDate',
				filterable: false,
				width: '8%',
				Cell: ({ value }) =>
					moment.utc(value).local().format(DATE_FORMAT),
			},
			{
				Header: t('shortCheckOutLabel'),
				accessor: 'finalDate',
				filterable: false,
				width: '8%',
				Cell: ({ value }) =>
					moment.utc(value).local().format(DATE_FORMAT),
			},
			{
				Header: 'Call center',
				accessor: 'callcenter.name',
				filterable: false,
				width: '9%',
			},
			{
				Header: t('adults'),
				accessor: 'adult',
				filterable: false,
				width: '4%',
			},
			{
				Header: t('status'),
				accessor: 'status.name',
				filterable: false,
				width: '7%',
			},
		],
		[t]
	);

	return (
		<Container fluid>
			<Row>
				<Col xxl={12}>
					<Card className="shadow">
						<CardBody className="pt-0">
							<div>
								{!isLoading ? (
									<>
										<TableContainer
											columns={columns}
											data={
												isSuccess
													? reservationData?.data
															?.list ?? []
													: []
											}
											className="custom-header-css"
											divClass="table-responsive mb-3"
											tableClass="align-middle"
										/>
										<PaginationManual
											query={queryFilter}
											setQuery={setQueryFilter}
											setQueryFilter={setQueryFilter}
											totalPages={
												reservationData?.data
													?.pagination?.totalPages ??
												1
											}
											showTotal={true}
											totalCount={
												reservationData?.data
													?.pagination?.totalCount ??
												0
											}
											isLoading={isFetching}
											labelForItem={{
												plural: t('reservations'),
												singular: t('reservation'),
											}}
										/>
									</>
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ReservationList;
