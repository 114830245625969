import { Col, Container, Input, Row } from 'reactstrap';
import useApplicationMenu from '../../../hooks/useApplicationMenu';
import LinkCard from '../../../Components/Common/LinkCard';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { filterByLabel } from '../../../util/filterByLabel';
import AlertMessage from '../../../Components/Common/AlertMessage';

const Applications = () => {
	const applications = useApplicationMenu();
	const { t } = useTranslation();
	const [searchBy, setSearchBy] = useState('');

	const items = useMemo(() => {
		return applications
			.filter((it) => it.show)
			.filter((it) => filterByLabel(it.label, searchBy));
	}, [applications, searchBy]);

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Row className="mb-5">
						<Col xs={12} md={{ offset: 3, size: 6 }}>
							<div className="d-flex align-items-center justify-content-center">
								<Input
									className="form-control"
									type="text"
									id="booking"
									value={searchBy}
									onChange={(e) =>
										setSearchBy(e.target.value)
									}
									placeholder={`${t(
										'menu.applications.search'
									)}...`}
								/>
							</div>
						</Col>
					</Row>

					<Row>
						<Col xs="12" md={{ offset: 2, size: 8 }}>
							<div
								className="d-flex flex-row"
								style={{ gap: '20px' }}
							>
								{items.length === 0 && (
									<div className="d-flex justify-content-center align-items-center w-100">
										<AlertMessage
											color="info"
											textColor="text-info text-center"
											message={t('messages.EMPTY_LIST')}
										/>
									</div>
								)}
								{items.map((it) => (
									<LinkCard
										key={it.id}
										label={t(it.label)}
										to={it.link}
										iconClass={it.icon}
									/>
								))}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Applications;
