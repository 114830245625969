import { useQuery } from 'react-query';
import { getVentasPaginate } from '../../helpers/ventas';

const useGetVentasPaginate = (queryPath) => {
	const query = useQuery(['getVentasPaginate', queryPath], () =>
		getVentasPaginate(queryPath)
	);
	return query;
};

export default useGetVentasPaginate;
