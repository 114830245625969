import { useTranslation } from 'react-i18next';
import { sourceAnalytics } from '../../../helpers/report';
import { useMutation } from 'react-query';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Label,
	Row,
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import useBanner from '../../../hooks/useBanner';
import SearchForm from '../../../Components/Report/SearchForm';
import { useMemo, useState } from 'react';
import ChartStats from '../../../Components/Report/Common/ChartStats';
import TableSource, {
	getItems,
} from '../../../Components/Report/Source/TableSource';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

const initialStateSearch = {
	dateRange: {
		start: '',
		end: '',
	},
	callCenters: [],
	hotels: [],
	segments: [],
	departments: [],
	dateOption: 'checkIn',
	sources: [],
};
const activeQuery = {
	agentsOpt: false,
	callCenterOpt: true,
	segmentOpt: true,
	hotelOpt: true,
	mealPlanOpt: false,
	departmentsOpt: true,
	saleStatusOpt: true,
	sourceOpt: true,
	dateOption: true,
};

const Source = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.source',
	});
	const { t: tColumn } = useTranslation('translation', {
		keyPrefix: 'components.tableSource',
	});
	document.title = t('header');
	const banner = useBanner();
	const [radioOption, setRadioOption] = useState('source');

	const {
		mutate: mutateReport,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(sourceAnalytics, {
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const { departments, hotels, callCenters, sources } = useMemo(() => {
		if (itemsData !== undefined) {
			return {
				hotels: itemsData.hotels.map((item) => ({
					name: item.hotel,
					total: item.total,
					percentage: item.percentage,
				})),
				callCenters: itemsData.callCenters.map((item) => ({
					name: item.callCenter,
					total: item.total,
					percentage: item.percentage,
				})),
				departments: itemsData.departments.map((item) => ({
					name: item.department,
					total: item.total,
					percentage: item.percentage,
				})),
				sources: itemsData.sources.map((item) => ({
					name: item.source ?? 'Otro',
					total: item.total,
					percentage: item.percentage,
				})),
			};
		}
		return {
			departments: [],
			hotels: [],
			callCenters: [],
			sources: [],
		};
	}, [itemsData]);

	const onHandleSubmit = (body) => {
		setRadioOption('source');
		mutateReport(body);
	};

	const labelReport = useMemo(() => {
		switch (radioOption) {
			case 'source':
				return t('reportBySource');
			case 'hotel':
				return t('reportByHotel');
			case 'departments':
				return t('reportByDepartments');
			case 'callcenter':
				return t('reportByCallcenter');
			default:
				return 'Report';
		}
	}, [t, radioOption]);

	const downloadToCSV = () => {
		const workbook = new ExcelJS.Workbook();
		workbook.creator = 'CRM - Sunset M4S';
		workbook.created = new Date();
		workbook.modified = new Date();
		workbook.lastPrinted = new Date();
		workbook.properties.date1904 = true;
		workbook.calcProperties.fullCalcOnLoad = true;

		const sheet = workbook.addWorksheet(labelReport, {
			headerFooter: {
				firstHeader: 'Hello Exceljs',
				firstFooter: 'Hello World',
			},
		});
		sheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

		const columns = [
			{
				key: 'DEPARTAMENTO',
				header: tColumn('department'),
				width: 35,
			},
			{
				key: 'BOOKING',
				header: 'ID Booking',
				width: 14,
			},
			{
				key: 'IDRESERVACION',
				header: tColumn('reservationId'),
				width: 14,
			},
			{
				key: 'NOMBRECLIENTE',
				header: tColumn('name'),
				width: 25,
			},
			{
				key: 'STATUS',
				header: tColumn('status'),
				width: 25,
			},
			{
				key: 'IDUSER',
				header: tColumn('agent'),
				width: 14,
			},
			{
				key: 'NOMBRECC',
				header: 'Call center',
				width: 18,
			},
			{
				key: 'HOTEL',
				header: tColumn('hotel'),
				width: 25,
			},
			{
				key: 'PAIS',
				header: tColumn('country'),
				width: 14,
			},
		];
		sheet.columns = columns;
		let posI = 0;
		const items = getItems(itemsData, radioOption);
		items.forEach((val, i, _) => {
			console.log(val);
			const value = {
				DEPARTAMENTO: val.name,
			};
			if (i === 0) {
				posI += 2;
			} else {
				posI += 1;
			}
			sheet.insertRow(posI, value);
			sheet.getCell(`A${posI}`).font = { bold: true };
			val.list.forEach((val2, idx, _) => {
				posI += 1;
				sheet.insertRow(posI, val2);
			});
		});
		sheet.getRow(1).font = { bold: true };
		sheet.getRow(1).border = {
			top: { style: 'medium', color: { argb: '021e4c' } },
			bottom: { style: 'medium', color: { argb: '021e4c' } },
		};

		workbook.xlsx
			.writeBuffer()
			.then((response) => {
				var blob = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				saveAs(
					blob,
					`${labelReport.replace(/ /g, '_')}${moment().format(
						'DDMMYYYYHHmmss'
					)}`
				);
			})
			.catch((error) => {
				console.log('Error');
				console.log(error);
			});
	};

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb
					title={t('title')}
					pageTitle={t('sources')}
					urlPageTitle="/revenue"
				/>

				<Row>
					<Col>
						<Card className="shadow">
							<CardBody className="pt-0">
								<div className="py-3">
									<SearchForm
										handleSubmit={onHandleSubmit}
										initialState={initialStateSearch}
										activeQuery={activeQuery}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col xs={12} lg={3}>
						<ChartStats
							items={sources}
							isSearching={isSearching}
							title={t('chartSourceTitle')}
							label={t('total')}
						/>
					</Col>
					<Col xs={12} lg={3}>
						<ChartStats
							items={hotels}
							isSearching={isSearching}
							title={t('chartHotelTitle')}
							label={t('total')}
						/>
					</Col>
					<Col xs={12} lg={3}>
						<ChartStats
							items={departments}
							isSearching={isSearching}
							title={t('chartDepartmentTitle')}
							label={t('total')}
						/>
					</Col>
					<Col xs={12} lg={3}>
						<ChartStats
							items={callCenters}
							isSearching={isSearching}
							title={t('chartCallCenterTitle')}
							label={t('total')}
						/>
					</Col>
				</Row>

				{!isSearching && itemsData && (
					<Card className="shadow-sm">
						<CardBody>
							<Row>
								<Col xs="12" md="12">
									<div className="d-flex justify-content-end align-items-center mb-2">
										<div className="d-flex flex-1">
											<div className="form-check me-4">
												<Input
													className="form-check-input"
													type="radio"
													id="showBySource"
													name="groupBy"
													checked={
														radioOption === 'source'
													}
													onChange={(evt) =>
														setRadioOption('source')
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="showBySource"
												>
													{t('showBySource')}
												</Label>
											</div>
											<div className="form-check me-4">
												<Input
													className="form-check-input"
													type="radio"
													id="showByHotel"
													name="groupBy"
													checked={
														radioOption === 'hotel'
													}
													onChange={(evt) =>
														setRadioOption('hotel')
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="showByHotel"
												>
													{t('showByHotel')}
												</Label>
											</div>
											<div className="form-check me-4">
												<Input
													className="form-check-input"
													type="radio"
													id="showByDepartments"
													name="groupBy"
													checked={
														radioOption ===
														'departments'
													}
													onChange={(evt) =>
														setRadioOption(
															'departments'
														)
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="showByDepartments"
												>
													{t('showByDepartments')}
												</Label>
											</div>
											<div className="form-check me-4">
												<Input
													className="form-check-input"
													type="radio"
													id="showByCallcenter"
													name="groupBy"
													checked={
														radioOption ===
														'callcenter'
													}
													onChange={(evt) =>
														setRadioOption(
															'callcenter'
														)
													}
												/>
												<Label
													className="form-check-label"
													htmlFor="showByCallcenter"
												>
													{t('showByCallcenter')}
												</Label>
											</div>
										</div>
										<div>
											<Button
												color="info"
												type="button"
												outline
												onClick={downloadToCSV}
												className="d-flex align-items-center"
											>
												<i className="ri ri-download-2-line me-2" />
												{t('download')}
											</Button>
										</div>
									</div>
								</Col>
								<Col xs="12" md="12">
									<TableSource
										items={itemsData}
										showBy={radioOption}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				)}
			</Container>
		</div>
	);
};

export default Source;
