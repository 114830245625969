import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Loader from '../../Common/Loader';
import { useTranslation } from 'react-i18next';
import PieChart from '../../Chart/PieChart';
import WidgetTotalIncome from '../ReservationProduction/WidgetTotalIncome';
import { useMemo } from 'react';

const ChartStats = ({ items, isSearching, title, label }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.chartStats',
	});
	const series = items.map((it) => it.total);
	const options = {
		chart: {
			height: 300,
			type: 'pie',
		},
		labels: items.map((it) => it.name ?? 'Otro'),
		legend: {
			show: false,
		},
		dataLabels: {
			dropShadow: {
				enabled: false,
			},
		},
		tooltip: {
			theme: 'dark',
			x: {
				show: false,
			},
			y: {
				formatter: function (value) {
					return value;
				},
				title: {
					formatter: function (seriesName) {
						return `${seriesName}:`;
					},
				},
			},
			items: {
				display: 'flex',
			},
		},
	};

	const sumTotal = useMemo(() => {
		if (!series) return 0;
		return series.reduce((acc, curr) => acc + curr, 0);
	}, [series]);

	return (
		<Card className="card-height-100">
			<CardHeader className="pb-0">
				<h4 className="card-title mb-2">{title}</h4>
				<Row>
					<Col>
						<WidgetTotalIncome
							total={sumTotal}
							label={label}
							iconClass="ri ri-hotel-line text-primary"
							spanClass="avatar-title bg-soft-primary text-primary rounded-2 fs-2"
						/>
					</Col>
				</Row>
			</CardHeader>
			<CardBody className="px-0">
				{isSearching ? (
					<Loader />
				) : items.length === 0 ? (
					<p className="px-2">{t('noDataAvailable')}</p>
				) : (
					<PieChart series={series} options={options} />
				)}
			</CardBody>
		</Card>
	);
};

export default ChartStats;
