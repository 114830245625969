import { useQuery } from 'react-query';
import { getReservationStatusAll } from '../../helpers/catalogues/reservation_status';

const useGetReservationStatusAllQuery = (enabled = true) => {
	const query = useQuery(
		['getReservationStatusAll'],
		async () => {
			const response = await getReservationStatusAll();
			return response;
		},
		{
			enabled: enabled,
			select: (response) =>
				response.data.list.map((it) => ({
					value: it.id,
					label: it.status,
					labelEn: it.statusEng,
				})),
		}
	);

	return query;
};

export default useGetReservationStatusAllQuery;
