import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FIELD_REQUIRED, UPDATE_SUCCESS } from '../../constants/messages';
import ButtonsLoader from '../../Loader/ButtonsLoader';
import useBanner from '../../../hooks/useBanner';
import { updateProgram } from '../../../helpers/catalogues/program';

const FormProgramTypes = ({ item = null, toggleModal, refetch }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.formProgramTypes',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const banner = useBanner();

	//update depart
	const { mutate: updateItem, isLoading: isUpdating } = useMutation({
		mutationFn: updateProgram,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			refetch();
			toggleModal();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			id: item.id,
			program: item?.program ?? '',
			clave: item?.clave ?? '',
			active: item?.active ?? false,
		},
		validationSchema: Yup.object({
			program: Yup.string().required(tMessage(FIELD_REQUIRED)),
			clave: Yup.string().required(tMessage(FIELD_REQUIRED)),
		}),
		onSubmit: async (values) => {
			//submit request
			const data = {};
			Object.entries(values).forEach((entry) => {
				const [key, value] = entry;
				data[key] = value;
			});
			updateItem({
				id: item?.id,
				body: data,
			});
		},
	});

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="program">
							{t('name')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.program ? 'is-invalid' : ''
							}`}
							id="program"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.program}
						/>
						{formik.errors.program && (
							<FormFeedback type="invalid d-block">
								{formik.errors.program}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="clave">
							{t('key')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.clave ? 'is-invalid' : ''
							}`}
							id="clave"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.clave}
						/>
						{formik.errors.clave && (
							<FormFeedback type="invalid d-block">
								{formik.errors.clave}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={4}>
					<div className="form-check mb-2 mt-3">
						<Input
							className="form-check-input"
							type="checkbox"
							id="active"
							checked={Boolean(formik.values.active)}
							onChange={(evt) =>
								formik.setFieldValue(
									'active',
									evt.target.checked ? 1 : 0
								)
							}
						/>
						<Label className="form-check-label" htmlFor="active">
							{t('enabled')}
						</Label>
					</div>
				</Col>
			</Row>
			{isUpdating ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleModal ? toggleModal : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default FormProgramTypes;
