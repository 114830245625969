import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { updateSource } from '../../../helpers/catalogues/source';
import {
	FIELD_REQUIRED,
	SELECT_OPTION,
	UPDATE_SUCCESS,
} from '../../constants/messages';
import ButtonsLoader from '../../Loader/ButtonsLoader';
import useBanner from '../../../hooks/useBanner';
import SelectAsync from '../../Common/SelectAsync';
import { getDepartmentPaginate } from '../../../helpers/catalogues/departments';

const FormSourceTypes = ({ item = null, toggleModal, refetch }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.formSource',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const banner = useBanner();

	//update depart
	const { mutate: updateItem, isLoading: isUpdating } = useMutation({
		mutationFn: updateSource,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			refetch();
			toggleModal();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			id: item.id,
			campaign: item?.campaign ?? '',
			enabled: item?.enabled ?? false,
			department: item?.department
				? { value: item.department.id, label: item.department.name }
				: null,
		},
		validationSchema: Yup.object({
			campaign: Yup.string().required(tMessage(FIELD_REQUIRED)),
		}),
		onSubmit: async (values) => {
			//submit request
			const data = {};
			Object.entries(values).forEach((entry) => {
				const [key, value] = entry;
				if (key === 'department') {
					data['departmentId'] = values?.department?.value ?? null;
					data['department'] = values?.department?.value
						? { id: values?.department?.value }
						: null;
				}
				data[key] = value;
			});
			updateItem({
				id: item?.id,
				body: data,
			});
		},
	});

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="campaign">
							{t('name')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.campaign ? 'is-invalid' : ''
							}`}
							id="campaign"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.campaign}
						/>
						{formik.errors.campaign && (
							<FormFeedback type="invalid d-block">
								{formik.errors.campaign}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg="4">
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="service">
							{t('department')}
						</Label>
						<SelectAsync
							fnFilter={getDepartmentPaginate}
							query={'?page=1&max=50'}
							keyCompare={'name'}
							value={formik.values.department}
							onChange={(value) => {
								formik.setFieldValue(`department`, value);
							}}
							placeholder={tMessage(SELECT_OPTION)}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="form-check mb-2 mt-3">
						<Input
							className="form-check-input"
							type="checkbox"
							id="enabled"
							checked={Boolean(formik.values.enabled)}
							onChange={(evt) =>
								formik.setFieldValue(
									'enabled',
									evt.target.checked ? 1 : 0
								)
							}
						/>
						<Label className="form-check-label" htmlFor="enabled">
							{t('enabled')}
						</Label>
					</div>
				</Col>
			</Row>
			{isUpdating ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleModal ? toggleModal : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default FormSourceTypes;
