import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { useMutation, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	FIELD_REQUIRED,
	SELECT_OPTION,
	UPDATE_SUCCESS,
} from '../../../constants/messages';
import removetEmptyObject from '../../../../util/removetEmptyObject';
import ButtonsLoader from '../../../Loader/ButtonsLoader';
import { useTranslation } from 'react-i18next';
import { updateDepartment } from '../../../../helpers/catalogues/departments';
import useBanner from '../../../../hooks/useBanner';
import { getServerList } from '../../../../helpers/external/call';
import Select from 'react-select';

const FormDepartment = ({ item = null, toggleModal, refetch }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.formDepartment',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const banner = useBanner();

	//update depart
	const { mutate: updateItem, isLoading: isUpdating } = useMutation({
		mutationFn: updateDepartment,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			refetch();
			toggleModal();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const { data: serverOpt } = useQuery(
		['getServerList'],
		async () => {
			const response = await getServerList();
			return response;
		},
		{
			select: (response) =>
				response.data.serversList.map((it) => ({
					value: it.key,
					label: it.value,
				})),
		}
	);

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			id: item.id,
			name: item?.name ?? '',
			callcenters: item?.callcenters ?? '',
			clave: item?.clave ?? '',
			contexto: item?.contexto ?? '',
			email: item?.email ?? '',
			generapoliza: item?.generapoliza ?? '',
			asteriskProvider: item?.asteriskProvider ?? '',
			asteriskServer: item?.asteriskServer ?? '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required(tMessage(FIELD_REQUIRED)),
		}),
		onSubmit: async (values) => {
			//submit request
			const data = {};
			Object.entries(removetEmptyObject(values)).forEach((entry) => {
				const [key, value] = entry;
				data[key] = value;
			});
			updateItem({
				id: item?.id,
				body: data,
			});
		},
	});

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="name">
							{t('name')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.name ? 'is-invalid' : ''
							}`}
							id="name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
						/>
						{formik.errors.name && (
							<FormFeedback type="invalid d-block">
								{formik.errors.name}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="contexto">
							{t('context')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.contexto ? 'is-invalid' : ''
							}`}
							id="contexto"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.contexto}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label
							className="form-label mb-0"
							htmlFor="asteriskServer"
						>
							{t('server')}
						</Label>
						<Select
							id="asteriskServer"
							className="mb-0 p-0 form-control-sm w-70"
							value={
								formik.values.asteriskServer
									? {
											value: formik.values.asteriskServer,
											label:
												serverOpt?.find(
													(it) =>
														it.value ===
														formik.values
															.asteriskServer
												)?.label ??
												formik.values.asteriskServer,
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue(
									'asteriskServer',
									value?.value ?? ''
								);
							}}
							options={serverOpt}
							placeholder={tMessage(SELECT_OPTION)}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label
							className="form-label mb-0"
							htmlFor="asteriskProvider"
						>
							{t('provider')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.asteriskProvider
									? 'is-invalid'
									: ''
							}`}
							id="asteriskProvider"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.asteriskProvider}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label
							className="form-label mb-0"
							htmlFor="callcenters"
						>
							Call center
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.callcenters ? 'is-invalid' : ''
							}`}
							id="callcenters"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.callcenters}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="email">
							{t('email')}
						</Label>
						<Input
							type="text"
							className={`form-control`}
							id="email"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.email}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="clave">
							{t('key')}
						</Label>
						<Input
							type="text"
							className={`form-control`}
							id="clave"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.clave}
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="form-check mb-2 mt-3">
						<Input
							className="form-check-input"
							type="checkbox"
							id="generapoliza"
							checked={Boolean(formik.values.generapoliza)}
							onChange={(evt) =>
								formik.setFieldValue(
									'generapoliza',
									evt.target.checked ? 1 : 0
								)
							}
						/>
						<Label
							className="form-check-label"
							htmlFor="generapoliza"
						>
							{t('generatesPolicy')}
						</Label>
					</div>
				</Col>
			</Row>
			{isUpdating ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleModal ? toggleModal : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default FormDepartment;
