import { useTranslation } from 'react-i18next';
import useGetCardsClientQuery from '../../../../hooks/Queries/useGetCardsClientQuery';
import Loader from '../../../Common/Loader';
import TableContainer from '../../../Common/TableContainer';
import { useMemo, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { addIconClass, deleteIconClass } from '../../../constants/icons';
import BasicModal from '../../../Common/BasicModal';
import FormCard from './CardsClient/FormCard';
import { deleteCard } from '../../../../helpers/cards';
import useBanner from '../../../../hooks/useBanner';
import { DELETE_SUCCESS } from '../../../constants/messages';
import { useMutation } from 'react-query';
import DeleteModal from '../../../Common/DeleteModal';
import CellActions from '../../../Common/CellActions';

const CardsClient = ({ customerId }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.operation.CardsClient',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const banner = useBanner();
	const [card, setCard] = useState(null);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const toggleModal = () => setShowAddModal(!showAddModal);

	const { data, isSuccess, isFetching, refetch } =
		useGetCardsClientQuery(customerId);

	const showDialogDelete = (row) => {
		const { original } = row;
		setCard(original);
		setShowDeleteDialog(true);
	};

	const actions = useMemo(
		() => [
			{
				iconClass: `${deleteIconClass} fs-5 text-danger`,
				click: showDialogDelete,
				labelTooltip: t('delete'),
			},
		],
		[t]
	);

	const { mutate: deleteCardMutation, isLoading: isDeleting } = useMutation({
		mutationFn: deleteCard,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(DELETE_SUCCESS));
			refetch();
			setShowDeleteDialog(false);
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const columns = useMemo(
		() => [
			{
				Header: t('cardHolderName'),
				accessor: 'customer',
				filterable: false,
				style: {
					width: '40%',
				},
				Cell: ({ value }) => `${value.firstName} ${value.lastName}`,
			},
			{
				Header: t('last4Digits'),
				accessor: 'cardLast4',
				filterable: false,
				style: {
					width: '20%',
				},
				Cell: ({ value }) => `**** ${value}`,
			},
			{
				Header: t('expiration'),
				accessor: 'expiration',
				filterable: false,
				style: {
					width: '15%',
				},
			},
			{
				id: 'action',
				style: {
					width: '5%',
				},
				Cell: ({ row }) => {
					return <CellActions actions={actions} row={row} />;
				},
			},
		],
		[t, actions]
	);

	const handleDelete = async () => {
		const dataToDelete = {
			id: card.id,
		};
		deleteCardMutation(dataToDelete);
	};

	if (isFetching) return <Loader />;
	if (!isFetching && isSuccess) {
		return (
			<>
				<Row className="mb-3 align-items-center">
					<Col xs="12" md={10}></Col>
					<Col xs="12" md={2}>
						<div className="d-flex align-items-center justify-content-end gap-2">
							<Button
								color="info"
								size="sm"
								onClick={toggleModal}
								className="d-flex align-items-center"
							>
								<i className={`${addIconClass} fs-5`} />{' '}
								{t('add')}
							</Button>
						</div>
					</Col>
				</Row>
				<TableContainer
					columns={columns}
					data={data}
					className="custom-header-css"
					divClass="table-responsive mb-3"
					tableClass="align-middle table-wrap"
					theadClass=""
				/>
				<BasicModal
					open={showAddModal}
					setOpen={setShowAddModal}
					title={t('newCard')}
					size="md"
					children={
						<FormCard
							closeModal={toggleModal}
							customerId={customerId}
						/>
					}
				/>
				<DeleteModal
					handleDelete={handleDelete}
					show={showDeleteDialog}
					setShow={setShowDeleteDialog}
					isDeleting={isDeleting}
				/>
			</>
		);
	}
};

export default CardsClient;
