import { useQuery } from 'react-query';
import { getDepartment } from '../../helpers/catalogues/departments';

const useGetDepartmentById = (id) => {
	const query = useQuery(['getDepartment', id], () => getDepartment(id), {
		enabled: Boolean(id),
		select: (result) => result.data,
	});
	return query;
};

export default useGetDepartmentById;
