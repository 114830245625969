import { ROLE_MANAGER, ROLE_SUPERVISOR } from '../Components/constants/roles';
import { useProfile } from '../Components/Hooks/UserHooks';
import { existsRole } from '../util/existsRole';

const useApplicationMenu = () => {
	const { roles } = useProfile();

	const applicationMenu = [
		{
			id: 'articleOfHelp',
			label: 'menu.configuration.articleOfHelp',
			icon: 'ri-article-line',
			link: '/article',
			show: existsRole(roles, [ROLE_MANAGER, ROLE_SUPERVISOR]),
		},
		{
			id: 'categoryOfArticle',
			label: 'menu.configuration.categoryOfArticle',
			icon: 'ri-align-justify',
			link: '/articleCategory',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'departament',
			label: 'menu.configuration.departament',
			icon: 'ri-function-line',
			link: '/departament',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'templateSms',
			label: 'menu.configuration.templateSms',
			icon: 'ri-chat-settings-line',
			link: '/templateSms',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'source',
			label: 'menu.configuration.source',
			icon: 'ri-open-source-line',
			link: '/sourceTypes',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'segments',
			label: 'menu.configuration.segments',
			icon: 'ri-bookmark-2-line',
			link: '/segmentTypes',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'program',
			label: 'menu.configuration.program',
			icon: 'ri-mini-program-line',
			link: '/programTypes',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'campaing',
			label: 'menu.configuration.campaign',
			icon: 'ri-mail-send-line',
			link: '/campaignTypes',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
		{
			id: 'callcenter',
			label: 'menu.configuration.callcenter',
			icon: 'ri-phone-line',
			link: '/callCenterTypes',
			show: existsRole(roles, [ROLE_MANAGER]),
		},
	];

	return applicationMenu;
};

export default useApplicationMenu;
