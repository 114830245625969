import { del, get, post } from './api_helper';
import * as url from './url';

const getCardsByCustomerId = async (customerId) =>
	await get(`${url.customerBankCard}?customerId=${customerId}`);
const createCard = async (data) => await post(`${url.customerBankCard}`, data);
const deleteCard = async (data) =>
	await del(`${url.customerBankCard}/${data.id}`);

export { getCardsByCustomerId, createCard, deleteCard };
