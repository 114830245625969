import { useQuery } from 'react-query';
import { getCardTypesAll } from '../../helpers/catalogues/cardType';

const useGetCardTypesAllQuery = () => {
	const query = useQuery(
		['getCardTypesAll'],
		async () => {
			const response = await getCardTypesAll();
			return response;
		},
		{
			select: (response) =>
				response.data.list.map((it) => ({
					value: it.id,
					label: it.card,
					type: it.type,
					alias: it.alias,
				})),
		}
	);
	return query;
};

export default useGetCardTypesAllQuery;
