import { useQuery } from 'react-query';
import { getVentasById } from '../../helpers/ventas';

const useGetVentasById = (id) => {
	const query = useQuery(['getVentasById', id], () => getVentasById(id), {
		enabled: !!id,
		select: (response) => response.data,
	});
	return query;
};

export default useGetVentasById;
