import { Col, Row } from 'reactstrap';
import FormReservationEdit from './FormReservationEdit';

const FormReservation = ({
	reservation = null,
	toggleDialog,
	refetchReservation,
}) => {
	return (
		<Row>
			<Col xs={12} md={12}>
				<FormReservationEdit
					reservation={reservation}
					toggleDialog={toggleDialog}
					refetchReservation={refetchReservation}
				/>
			</Col>
		</Row>
	);
};

export default FormReservation;
