import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, clearMessage } from '../slices/messages/reducer';
import { useTranslation } from 'react-i18next';
import extractMeaningfulMessage from '../util/extractMeaningfulMessage';
import { ERROR_SERVER } from '../Components/constants/messages';

const useBanner = () => {
	const dispatch = useDispatch();
	const messagesState = useSelector((state) => state.message);
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});

	const add = useCallback(
		(type, message) => {
			dispatch(
				addMessage({
					type,
					message,
				})
			);
		},
		[dispatch]
	);

	const simpleError = useCallback(
		(message) => {
			const mess = extractMeaningfulMessage(
				message,
				tMessage(ERROR_SERVER)
			);
			dispatch(
				addMessage({
					type: 'error',
					message: mess,
				})
			);
		},
		[dispatch, tMessage]
	);

	const simpleSuccess = useCallback(
		(message) => {
			dispatch(
				addMessage({
					type: 'success',
					message,
				})
			);
		},
		[dispatch]
	);

	const simpleWarning = useCallback(
		(message) => {
			dispatch(
				addMessage({
					type: 'warning',
					message,
				})
			);
		},
		[dispatch]
	);

	const simpleInfo = useCallback(
		(message) => {
			dispatch(
				addMessage({
					type: 'info',
					message,
				})
			);
		},
		[dispatch]
	);

	const clear = useCallback(() => {
		dispatch(clearMessage());
	}, [dispatch]);

	return useMemo(
		() => ({
			show: add,
			clear,
			simpleError,
			simpleSuccess,
			simpleWarning,
			simpleInfo,
		}),
		[add, clear, simpleError, simpleInfo, simpleSuccess, simpleWarning]
	);
};

export default useBanner;
