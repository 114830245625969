import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FIELD_REQUIRED, UPDATE_SUCCESS } from '../../constants/messages';
import ButtonsLoader from '../../Loader/ButtonsLoader';
import useBanner from '../../../hooks/useBanner';
import { updateCampaign } from '../../../helpers/catalogues/campaign';
import SelectAsync from '../../Common/SelectAsync';
import { getCallCenterPaginate } from '../../../helpers/catalogues/call_center';

const FormCampaignTypes = ({ item = null, toggleModal, refetch }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.formCampaignTypes',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const banner = useBanner();

	//update depart
	const { mutate: updateItem, isLoading: isUpdating } = useMutation({
		mutationFn: updateCampaign,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			refetch();
			toggleModal();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			id: item.id,
			name: item?.name ?? '',
			callcenter: item?.callcenter ?? null,
			active: item?.active ?? false,
			destinies: item?.destinies ?? '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required(tMessage(FIELD_REQUIRED)),
			callcenter: Yup.object()
				.shape({
					id: Yup.string().required(tMessage(FIELD_REQUIRED)),
				})
				.nullable(),
		}),
		onSubmit: async (values) => {
			//submit request
			const data = {};
			Object.entries(values).forEach((entry) => {
				const [key, value] = entry;
				data[key] = value;
			});
			updateItem({
				id: item?.id,
				body: data,
			});
		},
	});

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col lg={4}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="name">
							{t('name')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.name ? 'is-invalid' : ''
							}`}
							id="name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
						/>
						{formik.errors.name && (
							<FormFeedback type="invalid d-block">
								{formik.errors.name}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={6}>
					<div className="mb-2">
						<Label
							htmlFor="call-center"
							className="form-label text-muted mb-0"
						>
							Call Center
						</Label>
						<SelectAsync
							fnFilter={getCallCenterPaginate}
							query={'?page=1&max=10&active=1'}
							keyCompare={'name'}
							value={
								formik.values.callcenter
									? {
											value: formik.values.callcenter.id,
											label: formik.values.callcenter
												.name,
									  }
									: null
							}
							onChange={(value) => {
								if (value) {
									formik.setFieldValue(
										'callcenter.id',
										value.value
									);
									formik.setFieldValue(
										'callcenter.name',
										value.label
									);
								} else {
									formik.setFieldValue('callcenter', null);
								}
							}}
						/>
						{formik.errors.callcenter && (
							<FormFeedback type="invalid d-block">
								{formik.errors.callcenter.id}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={2}>
					<div className="form-check mb-2 mt-3">
						<Input
							className="form-check-input"
							type="checkbox"
							id="active"
							checked={Boolean(formik.values.active)}
							onChange={(evt) =>
								formik.setFieldValue(
									'active',
									evt.target.checked ? 1 : 0
								)
							}
						/>
						<Label className="form-check-label" htmlFor="active">
							{t('enabled')}
						</Label>
					</div>
				</Col>
				<Col lg={12}>
					<div className="mb-3">
						<Label className="form-label" htmlFor="note">
							{t('observation')}
						</Label>
						<textarea
							id="note"
							name="note"
							className={`form-control`}
							value={formik.values.destinies}
							onChange={(e) =>
								formik.setFieldValue(
									'destinies',
									e.target.value
								)
							}
							rows={7}
						/>
					</div>
				</Col>
			</Row>
			{isUpdating ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleModal ? toggleModal : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default FormCampaignTypes;
