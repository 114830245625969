import {
	Button,
	CardBody,
	Col,
	Collapse,
	Form,
	FormFeedback,
	Input,
	Label,
	Row,
	Table,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import DatePicker from '../../../../Common/DatePicker';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	FIELD_GREATER_THAN_CERO,
	FIELD_INTEGER,
	FIELD_NUMERIC,
	FIELD_POSITIVE,
	FIELD_REQUIRED,
	SAVE_SUCCESS,
	SELECT_OPTION,
} from '../../../../constants/messages';
import { useFormik, Field, FormikProvider, FieldArray } from 'formik';
import useUser from '../../../../../hooks/useUser';
import * as Yup from 'yup';
import { useState, useMemo } from 'react';
import { getServicesByReservation } from '../../../../../helpers/reservation';
import { addIconClass, deleteIconClass } from '../../../../constants/icons';
import jsFormatNumber from '../../../../../util/jsFormatNumber';
import { getCurrencyAll } from '../../../../../helpers/catalogues/currencyType';
import { getAffiliationAll } from '../../../../../helpers/catalogues/affiliation';
import removetEmptyObject from '../../../../../util/removetEmptyObject';
import moment from 'moment';
import { createPayment, updatePayment } from '../../../../../helpers/payments';
import ButtonsLoader from '../../../../Loader/ButtonsLoader';
import { getBankAll } from '../../../../../helpers/catalogues/bank';
import { getAgentsBySupervisor } from '../../../../../helpers/customer';
import useRole from '../../../../../hooks/useRole';
import { getCardFlag } from '../../../../../util/getCardFlag';
import creditCardType from 'credit-card-type';
import { DATE_FORMAT } from '../../../../../common/globalsProp';
import useBanner from '../../../../../hooks/useBanner';
import useGetCardTypesAllQuery from '../../../../../hooks/Queries/useGetCardTypesAllQuery';
import useGetCardsClientQuery from '../../../../../hooks/Queries/useGetCardsClientQuery';
import FormCard from '../../../Lead/Tab/CardsClient/FormCard';
import useGetDepartmentById from '../../../../../hooks/Queries/useGetDepartmentById';

const getContractedServiceParsed = (paymentServices) => {
	return paymentServices?.map((it) => ({
		id: it.id,
		idBooking: it.serviceContract.idBooking,
		idService: it.serviceContract.idService,
		label: it.serviceContract.subService.name,
		amount: it.serviceContract.amount,
		price: it.serviceContract.amount,
		pax: it.serviceContract.pax,
		description: it.serviceContract.description,
		balance: it.serviceContract.amount,
		toPay: it.amount,
	}));
};

const getUserComission = (paymentServices) => {
	if (paymentServices.length > 0) {
		return paymentServices[0]?.serviceContract?.userComission ?? '';
	} else {
		return '';
	}
};

const FormPaymentClient = ({ toggleDialog, reservation, payment }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.operation.formPaymentClient',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const banner = useBanner();
	const user = useUser();
	const { isAgent } = useRole();
	const queryClient = useQueryClient();
	const { data: department } = useGetDepartmentById(5);
	const [service, setService] = useState(null);
	const { data: cardTypesOpt } = useGetCardTypesAllQuery();
	//get cards by customer
	const [openCollapse, setOpenCollapse] = useState(false);
	const [tarjeta, setTarjeta] = useState(null);
	const {
		data: customerCardsOpt,
		isFetching,
		refetch,
	} = useGetCardsClientQuery(reservation?.customer?.id);
	//services
	const { data: servicesOpt } = useQuery(
		['getServiceByReservation', reservation.id],
		async () => {
			const response = await getServicesByReservation(reservation.id);
			return response;
		},
		{
			keepPreviousData: true,
			select: (response) =>
				response.data.list
					.filter((it) => it.balance > 0)
					.map((it) => ({
						value: it.idService,
						label: it.subService?.name ?? it.description,
						booking: it.idBooking,
						amount: it.amount,
						price: it.subService?.price ?? 0,
						pax: it.pax,
						description: it.description,
						balance: it.balance,
						toPay: it.balance,
					})),
		}
	);
	//currencies
	const { data: currencyOpt } = useQuery(
		['getCurrencyAll'],
		async () => {
			const response = await getCurrencyAll();
			return response;
		},
		{
			select: (response) =>
				response.data.list.map((it) => ({
					value: it.id.toString(),
					label: `(${it.isoCode}) ${it.currency}`,
					exchangeRate: it.exchangeRate,
					isoCode: it.isoCode,
				})),
		}
	);
	//affiliation
	const { data: affiliationOpt } = useQuery(
		['getAffiliationAll'],
		async () => {
			const response = await getAffiliationAll();
			return response;
		},
		{
			select: (response) =>
				response.data.map((it) => ({
					value: it.id,
					label: it.affiliationDescription,
				})),
		}
	);
	//banco o terminal
	const { data: bankOpt } = useQuery(
		['getBankAll'],
		async () => await getBankAll(),
		{
			select: (response) =>
				response.data.list
					.filter((it) => it.active)
					.map((it) => ({
						value: it.id,
						label: it.name,
						exchangeRate: it.exchangeRate,
					})),
		}
	);

	//create payment
	const { mutate: create, isLoading: isCreating } = useMutation(
		createPayment,
		{
			onSuccess: () => {
				banner.simpleSuccess(tMessage(SAVE_SUCCESS));
				queryClient.refetchQueries({
					queryKey: ['getPaymentsByReservation'],
				});
				queryClient.refetchQueries({
					queryKey: ['getServiceByReservation'],
				});
				queryClient.refetchQueries({
					queryKey: ['getReservation'],
				});
				toggleDialog();
			},
		}
	);
	//update payment
	const { mutate: update, isLoading: isUpdating } = useMutation(
		updatePayment,
		{
			onSuccess: () => {
				banner.simpleSuccess(tMessage(SAVE_SUCCESS));
				queryClient.refetchQueries({
					queryKey: ['getPaymentsByReservation'],
				});
				queryClient.refetchQueries({
					queryKey: ['getServiceByReservation'],
				});
				toggleDialog();
			},
		}
	);
	const formik = useFormik({
		initialValues: {
			idPayment: payment?.idPayment ?? '',
			idReservation: payment?.idReservation ?? reservation.id,
			cardHolderName: payment?.cardHolderName ?? '',
			cardHolderLastName: payment?.cardHolderLastName ?? '',
			bank: payment?.bank?.id ?? '',
			type: payment?.type ?? '',
			amount: payment?.amount ?? '',
			currency: payment?.currency ?? { id: '' },
			creditCard: payment?.creditCard ?? '',
			idCardType: payment?.cardType?.id ?? '',
			expiration: payment?.expiration ?? '',
			authorization: payment?.authorization ?? '',
			user: payment?.user ?? user?.usuario,
			department: payment?.department ?? {
				id: parseInt(user?.deptoid),
			},
			multi: payment?.multi ?? '',
			exchangeRateTC: payment?.exchangeRateTC ?? '',
			amountMXN: payment?.amountMXN ?? '',
			exchangeRate: payment?.exchangeRate ?? '',
			paymentDate: payment?.paymentDate ?? moment().toDate(),
			contractedServices: getContractedServiceParsed(
				payment?.paymentServices ?? []
			),
			idInvoice: payment?.idInvoice ?? '',
			affiliation: payment?.affiliation ?? { id: '' },
			//temp
			cvv: '',
			userComission: getUserComission(payment?.paymentServices ?? []),
			sendPaymentEmail: false,
		},
		validationSchema: Yup.object({
			cardHolderName: Yup.string().required(tMessage(FIELD_REQUIRED)),
			cardHolderLastName: Yup.string().required(tMessage(FIELD_REQUIRED)),
			creditCard: Yup.string().required(tMessage(FIELD_REQUIRED)),
			expiration: Yup.string().required(tMessage(FIELD_REQUIRED)),
			idCardType: Yup.string().required(tMessage(FIELD_REQUIRED)),
			authorization: Yup.string().required(tMessage(FIELD_REQUIRED)),
			paymentDate: Yup.string().required(tMessage(FIELD_REQUIRED)),
			bank: Yup.string().required(tMessage(FIELD_REQUIRED)),
			affiliation: Yup.object().shape({
				id: Yup.string().required(tMessage(FIELD_REQUIRED)),
			}),
			currency: Yup.object().shape({
				id: Yup.number().required(tMessage(FIELD_REQUIRED)),
			}),
			amount: Yup.number()
				.min(0, tMessage(FIELD_POSITIVE))
				.integer(tMessage(FIELD_INTEGER))
				.typeError(tMessage(FIELD_NUMERIC))
				.required(tMessage(FIELD_REQUIRED)),
			amountMXN: Yup.number()
				.min(0, tMessage(FIELD_POSITIVE))
				.integer(tMessage(FIELD_INTEGER))
				.typeError(tMessage(FIELD_NUMERIC))
				.required(tMessage(FIELD_REQUIRED)),
			contractedServices: Yup.array()
				.of(
					Yup.object().shape({
						balance: Yup.number().required(),
						toPay: Yup.number()
							.min(1, tMessage(FIELD_GREATER_THAN_CERO))
							.integer(tMessage(FIELD_INTEGER))
							.typeError(tMessage(FIELD_NUMERIC))
							.required(tMessage(FIELD_REQUIRED))
							.test(
								'is-lower',
								'El pago debe ser menor que el saldo',
								function (value) {
									const balance = parseInt(
										this.parent.balance
									);
									const valueParsed = parseInt(value);
									return !(valueParsed > balance);
								}
							),
					})
				)
				.min(1, 'Al menos debes seleccionar 1 servicio'),
		}),
		onSubmit: async (values) => {
			//submit request
			//console.log(values);
			const data = {};
			Object.entries(removetEmptyObject(values)).forEach((entry) => {
				const [key, value] = entry;
				if (key === 'paymentDate') {
					data[key] = moment(values.paymentDate).format('YYYY-MM-DD');
				}
				if (key === 'contractedServices') {
					data[key] = values.contractedServices.map((it) => ({
						...it,
						toPay: it.toPay.toString(),
						userComission: values.userComission
							? values.userComission
							: user.usuario,
					}));
				} else {
					data[key] = value;
				}
			});
			if (values.idReservation && values.idPayment) {
				// updating existing one
				update({
					idReservation: values.idReservation,
					idPayment: values.idPayment,
					body: data,
				});
			} else {
				//creating one
				create(data);
			}
		},
	});

	const addService = () => {
		//console.log(service);
		const copyServices = [...formik.values.contractedServices];
		copyServices.push({
			idBooking: service.booking,
			idService: service.value,
			label: service.label,
			amount: service.amount,
			price: service.price,
			pax: service.pax,
			description: service.description,
			balance: service.balance,
			toPay: service.balance,
		});
		formik.setFieldValue('contractedServices', copyServices);
		const totalAmount = copyServices.reduce(
			(acc, curr) => acc + curr.toPay,
			0
		);
		formik.setFieldValue('amountMXN', totalAmount);
		formik.setFieldValue('amount', totalAmount);
		setService(null);
	};
	const { data: agentsOpt } = useQuery(
		['getAgentsBySupervisor', user.usuario],
		() => getAgentsBySupervisor(user.usuario),
		{
			enabled: user !== null && !isAgent,
			select: (result) =>
				result.data.list.map((it) => ({
					value: it.id,
					label: it.id,
				})) ?? [],
		}
	);

	const serviceOptUniques = useMemo(() => {
		if (servicesOpt !== undefined && servicesOpt.length > 0) {
			const uniqueArray = servicesOpt.filter(
				(it) =>
					!formik.values.contractedServices
						.map((itC) => itC.idService)
						.includes(it.value)
			);
			return uniqueArray;
		} else {
			return [];
		}
	}, [servicesOpt, formik.values.contractedServices]);

	const totalAmount = useMemo(() => {
		if (formik.values.contractedServices.length > 0) {
			const total = formik.values.contractedServices.reduce(
				(acc, curr) => acc + parseInt(curr.toPay),
				0
			);
			formik.setFieldValue('amountMXN', total);
			formik.setFieldValue('amount', total);
			return total;
		} else {
			formik.setFieldValue('amountMXN', 0);
			formik.setFieldValue('amount', 0);
			return 0;
		}
	}, [formik.values.contractedServices]);

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row className="align-items-end">
				<Col xs="10">
					<Label className="form-label mb-1" htmlFor="services">
						{t('service')}s
					</Label>
					<Select
						value={service}
						onChange={(value) => setService(value)}
						options={serviceOptUniques}
						name="choices-single-default"
						placeholder={tMessage(SELECT_OPTION)}
						id="services"
					/>
				</Col>
				<Col xs="2">
					<Button
						color="info"
						onClick={addService}
						disabled={!service}
						type="button"
					>
						<i className={`${addIconClass} fs-5`} />
					</Button>
				</Col>
				<Col xs="12">
					{!Array.isArray(formik.errors.contractedServices) &&
						formik.errors.contractedServices && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.contractedServices}
							</FormFeedback>
						)}
				</Col>
			</Row>
			{formik.values.contractedServices.length > 0 && (
				<Row className="mt-4">
					<Col>
						<FormikProvider value={formik}>
							<FieldArray
								name="contractedServices"
								render={(arrayHelper) => (
									<Row>
										<Col>
											<Table className="w-100 fs-7 table-sm align-middle">
												<thead className="table-light">
													<tr>
														<th width="40%">
															{t('service')}
														</th>
														<th width="10%">Pax</th>
														<th width="15%">
															{t('amount')}
														</th>
														<th width="15%">
															{t('balance')}
														</th>
														<th width="15%">
															{t('toPay')}
														</th>
														<th width="5%"></th>
													</tr>
												</thead>
												<tbody>
													{formik.values.contractedServices.map(
														(it, idx) => (
															<tr
																key={`service-${idx}`}
															>
																<td>
																	{it.label}
																</td>
																<td>
																	{it.pax}
																</td>
																<td>
																	{jsFormatNumber(
																		it.price
																	)}
																</td>
																<td>
																	{jsFormatNumber(
																		it.balance
																	)}
																</td>
																<td>
																	<Field
																		className={`form-control ${
																			formik
																				.errors
																				.contractedServices &&
																			formik
																				.errors
																				.contractedServices[
																				idx
																			]
																				?.toPay
																				? 'is-invalid'
																				: ''
																		}`}
																		name={`contractedServices.${idx}.toPay`}
																	/>
																	{formik
																		.errors
																		.contractedServices &&
																		formik
																			.errors
																			.contractedServices[
																			idx
																		]
																			?.toPay && (
																			<FormFeedback
																				type="invalid"
																				className="d-block"
																			>
																				{
																					formik
																						.errors
																						.contractedServices[
																						idx
																					]
																						.toPay
																				}
																			</FormFeedback>
																		)}
																</td>
																<td>
																	<Button
																		type="button"
																		color="danger"
																		size="sm"
																		onClick={() =>
																			arrayHelper.remove(
																				idx
																			)
																		}
																	>
																		<i
																			className={`${deleteIconClass} fs-5`}
																		/>
																	</Button>
																</td>
															</tr>
														)
													)}
												</tbody>
											</Table>
										</Col>
									</Row>
								)}
							/>
						</FormikProvider>
					</Col>
				</Row>
			)}
			{formik.values.contractedServices.length > 0 && <hr />}
			<Row className="align-items-end mb-1">
				<Col lg="6">
					<Label className="form-label mb-1" htmlFor="services">
						Seleccionar tarjeta
					</Label>
					<Select
						value={tarjeta}
						onChange={(value) => setTarjeta(value)}
						options={customerCardsOpt?.map((it) => ({
							value: it.id,
							label: `${it.name} ${it.lastName}. Tarjeta:**${it.cardLast4}. Exp:${it.expiration}`,
						}))}
						name="card"
						placeholder={tMessage(SELECT_OPTION)}
						id="card"
						isClearable
					/>
				</Col>
				<Col xs="2">
					<Button
						color="light"
						onClick={() => setOpenCollapse(!openCollapse)}
						type="button"
						disabled={Boolean(tarjeta)}
					>
						<i className={`${addIconClass} fs-5`} />{' '}
						{openCollapse ? 'Cancelar' : 'Agregar nueva'}
					</Button>
				</Col>
			</Row>
			<Collapse isOpen={openCollapse} id="create-card">
				<div className="card mb-0">
					<CardBody>
						<FormCard
							closeModal={() => setOpenCollapse(false)}
							customerId={reservation?.customer?.id}
							isFromReservation={true}
							refetch={refetch}
						/>
					</CardBody>
				</div>
			</Collapse>
			<Row className="mt-2">
				{/* <Col lg={6}>
					<div className="mb-2">
						<Label
							className="form-label mb-1"
							htmlFor="cardHolderName"
						>
							{t('name')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.cardHolderName ? 'is-invalid' : ''
							}`}
							id="cardHolderName"
							name="cardHolderName"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.cardHolderName}
						/>
						{formik.errors.cardHolderName && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.cardHolderName}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={6}>
					<div className="mb-2">
						<Label
							className="form-label mb-1"
							htmlFor="cardHolderLastName"
						>
							{t('lastName')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.cardHolderLastName
									? 'is-invalid'
									: ''
							}`}
							id="cardHolderLastName"
							name="cardHolderLastName"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.cardHolderLastName}
						/>
						{formik.errors.cardHolderLastName && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.cardHolderLastName}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs={12} md={3}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="cardType">
							{t('cardType')}
						</Label>
						<Select
							value={
								formik.values.idCardType
									? {
											value: formik.values.idCardType,
											label:
												cardTypesOpt?.find(
													(it) =>
														it.value ===
														formik.values.idCardType
												)?.label ?? '',
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue('idCardType', value.value);
							}}
							options={cardTypesOpt}
							name="choices-single-default"
							placeholder={tMessage(SELECT_OPTION)}
							id="cardType"
						/>
						{formik.errors.idCardType && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.idCardType}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={3}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="creditCard">
							{t('card')}
						</Label>
						<div className="input-group">
							<InputMask
								id="creditCard"
								className={`form-control ${
									formik.errors.creditCard ? 'is-invalid' : ''
								}`}
								mask="9999 9999 9999 9999"
								maskChar=" "
								placeholder="xxxx xxxx xxxx xxxx"
								value={formik.values.creditCard}
								onChange={(e) => {
									let val = e.target.value.replace(
										/[^0-9]/g,
										''
									);
									formik.setFieldValue('creditCard', val);
									//checamos q tarjeta es
									const types = creditCardType(val);
									if (val === '') {
										formik.setFieldValue('idCardType', '');
									} else if (types.length === 1) {
										const type = types[0].niceType;
										const cardType =
											cardTypesOpt.find((it) =>
												it.label
													.replace(/\s+/g, '')
													.toLowerCase()
													.includes(
														type
															.replace(/\s+/g, '')
															.toLowerCase()
													)
											)?.value ?? '';
										formik.setFieldValue(
											'idCardType',
											cardType
										);
									}
								}}
							/>
							<div className="input-group-text bg-light text-dark">
								{getCardFlag(formik.values.creditCard)}
							</div>
						</div>

						{formik.errors.creditCard && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.creditCard}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={2}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="expiration">
							{t('expirationDate')}
						</Label>
						<InputMask
							id="expiration"
							className={`form-control ${
								formik.errors.expiration ? 'is-invalid' : ''
							}`}
							mask="99/99"
							maskChar=" "
							placeholder="MM/YY"
							value={formik.values.expiration}
							onChange={(e) => {
								formik.setFieldValue(
									'expiration',
									e.target.value
								);
							}}
						/>
						{formik.errors.expiration && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.expiration}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={1}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="cvv">
							CVV
						</Label>
						<InputMask
							id="cvv"
							className={`form-control ${
								formik.errors.cvv ? 'is-invalid' : ''
							}`}
							mask="999"
							maskChar=" "
							placeholder="xxx"
							value={formik.values.cvv}
							onChange={(e) => {
								formik.setFieldValue('cvv', e.target.value);
							}}
						/>
						{formik.errors.cvv && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.cvv}
							</FormFeedback>
						)}
					</div>
				</Col> */}
				<Col lg={3}>
					<div className="mb-2">
						<Label
							className="form-label mb-1"
							htmlFor="paymentDate"
						>
							{t('paymentDate')}
						</Label>
						{isAgent ? (
							<div className="form-control">
								{moment(formik.values.paymentDate).format(
									DATE_FORMAT
								)}
							</div>
						) : (
							<DatePicker
								id="paymentDate"
								date={formik.values.paymentDate}
								onChangeDate={(value) => {
									if (value.length > 0) {
										formik.setFieldValue(
											`paymentDate`,
											value[0]
										);
									} else {
										formik.setFieldValue(
											`paymentDate`,
											null
										);
									}
								}}
							/>
						)}

						{formik.errors.paymentDate && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.paymentDate}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs={12} md={3}>
					<div className="mb-2">
						<Label
							className="form-label mb-1"
							htmlFor="terminalType"
						>
							{t('terminalType')}
						</Label>
						<Select
							value={
								formik.values.bank
									? {
											value: formik.values.bank,
											label:
												bankOpt?.find(
													(it) =>
														it.value ===
														formik.values.bank
												)?.label ?? '',
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue(
									'bank',
									value?.value ?? ''
								);
							}}
							options={bankOpt}
							name="choices-single-default"
							id="terminalType"
						/>
						{formik.errors.bank && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.bank}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs={12} md={3}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="membership">
							{t('membership')}
						</Label>
						<Select
							value={
								formik.values.affiliation?.id
									? {
											value: formik.values.affiliation.id,
											label:
												affiliationOpt?.find(
													(it) =>
														it.value ===
														formik.values
															.affiliation.id
												)?.label ?? '',
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue(
									'affiliation.id',
									value?.value ?? ''
								);
							}}
							options={affiliationOpt}
							name="choices-single-default"
							id="membership"
						/>
						{formik.errors.affiliation?.id && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.affiliation.id}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={3}>
					<div className="mb-2">
						<Label
							className="form-label mb-1"
							htmlFor="authorization"
						>
							{t('noAuthorization')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.authorization ? 'is-invalid' : ''
							}`}
							id="authorization"
							name="authorization"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.authorization}
						/>
						{formik.errors.authorization && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.authorization}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={3}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="idInvoice">
							{t('invoice')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.idInvoice ? 'is-invalid' : ''
							}`}
							id="idInvoice"
							name="idInvoice"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.idInvoice}
						/>
					</div>
				</Col>

				<Col xs={12} md={3}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="currency">
							{t('currency')}
						</Label>
						<Select
							value={
								formik.values.currency?.id
									? {
											value: formik.values.currency.id,
											label:
												currencyOpt?.find(
													(it) =>
														it.value ===
														formik.values.currency
															.id
												)?.label ?? '',
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue(
									'currency.id',
									value?.value ?? ''
								);
								formik.setFieldValue(
									'exchangeRate',
									value.exchangeRate
								);
								formik.setFieldValue(
									'exchangeRateTC',
									value.exchangeRate
								);
							}}
							options={currencyOpt}
							name="choices-single-default"
							id="currency"
						/>
						{formik.errors.currency?.id && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.currency.id}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col md={3}>
					<div className="mb-2">
						<Label
							className="form-label mb-1"
							htmlFor="exchangeRate"
						>
							Tipo de cambio
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.exchangeRate ? 'is-invalid' : ''
							}`}
							id="exchangeRate"
							name="exchangeRate"
							onChange={(e) => {
								formik.setFieldValue(
									'exchangeRate',
									e.target.value
								);
								formik.setFieldValue(
									'exchangeRateTC',
									e.target.value
								);
							}}
							onBlur={formik.handleBlur}
							value={formik.values.exchangeRate}
						/>
						{formik.errors.exchangeRate && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.exchangeRate}
							</FormFeedback>
						)}
					</div>
				</Col>

				<Col lg={3}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="amountMXN">
							{t('amount')}
						</Label>
						<div className="form-control">
							{jsFormatNumber(totalAmount)}
						</div>
					</div>
				</Col>
				<Col lg={3}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="amount">
							{t('amount')} (USD)
						</Label>
						<div className="form-control">
							{jsFormatNumber(totalAmount)}
						</div>
					</div>
				</Col>
				{!isAgent && (
					<>
						{department?.paymentForAnother && (
							<Col lg={3}>
								<div className="mb-2">
									<Label
										className="form-label mb-0 d-flex align-items-center"
										htmlFor="agent"
									>
										Usuario comisión
									</Label>
									<Select
										value={
											formik.values.userComission
												? {
														value: formik.values
															.userComission,
														label: formik.values
															.userComission,
												  }
												: null
										}
										onChange={(value) => {
											formik.setFieldValue(
												'userComission',
												value.value
											);
										}}
										options={agentsOpt}
										classNamePrefix="select2-selection"
										placeholder={tMessage(SELECT_OPTION)}
									/>
								</div>
							</Col>
						)}
						<Col lg={3}>
							<div className="mb-2">
								<Label
									className="form-label mb-0 d-flex align-items-center"
									htmlFor="agent"
								>
									Usuario pago
								</Label>
								<Select
									value={
										formik.values.user
											? {
													value: formik.values.user,
													label: formik.values.user,
											  }
											: null
									}
									onChange={(value) => {
										formik.setFieldValue(
											'user',
											value.value
										);
									}}
									options={agentsOpt}
									classNamePrefix="select2-selection"
									placeholder={tMessage(SELECT_OPTION)}
								/>
							</div>
						</Col>
					</>
				)}
				<Col lg={2}>
					<div className="form-check mt-3">
						<Input
							className="form-check-input"
							type="checkbox"
							id="active"
							checked={formik.values.sendPaymentEmail}
							onChange={(evt) =>
								formik.setFieldValue(
									'sendPaymentEmail',
									evt.target.checked
								)
							}
						/>
						<Label className="form-check-label" htmlFor="active">
							Enviar comprobante de pago
						</Label>
					</div>
				</Col>
			</Row>

			{(isCreating || isUpdating) && (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			)}

			{!isCreating && !isUpdating && (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleDialog ? toggleDialog : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default FormPaymentClient;
