import { useQuery } from 'react-query';
import { getCampaingPaginate } from '../../helpers/catalogues/campaign';

const useGetCampaignPaginateQuery = ({
	queryPath,
	enabled = true,
	full = true,
	configQuery = {},
}) => {
	const query = useQuery(
		['getCampaingPaginate', queryPath],
		() => getCampaingPaginate(`?${queryPath}`),
		{
			enabled: Boolean(queryPath) && enabled,
			select: (result) => {
				if (full) {
					return result;
				} else {
					return (
						result.data.list.map((it) => ({
							value: it.id,
							label: it.name,
						})) ?? []
					);
				}
			},
			...configQuery,
		}
	);

	return query;
};

export default useGetCampaignPaginateQuery;
