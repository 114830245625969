import { useQuery } from 'react-query';
import { getCardsByCustomerId } from '../../helpers/cards';
import useBanner from '../useBanner';

const useGetCardsClientQuery = (customerId) => {
	const banner = useBanner();
	const query = useQuery(
		['getCardsByCustomerId', customerId],
		async () => {
			const response = await getCardsByCustomerId(customerId);
			return response;
		},
		{
			enabled: !!customerId,
			select: (result) => result.data?.list ?? [],
		}
	);

	if (!query.isFetching && query.isError) {
		banner.simpleError(query.error);
	}

	return query;
};

export default useGetCardsClientQuery;
