import { Button, Col, Form, FormFeedback, Label, Row } from 'reactstrap';
import {
	FIELD_REQUIRED,
	SAVE_SUCCESS,
	SELECT_OPTION,
	UPDATE_SUCCESS,
} from '../../../../constants/messages';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import removetEmptyObject from '../../../../../util/removetEmptyObject';
import { useMutation, useQuery } from 'react-query';
import ButtonsLoader from '../../../../Loader/ButtonsLoader';
import { useTranslation } from 'react-i18next';
import useBanner from '../../../../../hooks/useBanner';
import {
	createPrecall,
	updatePrecall,
} from '../../../../../helpers/reservation';
import Select from 'react-select';
import { qualifyOpt } from '../../../../constants/qualify';
import { getListEmotionsClients } from '../../../../../helpers/notes';
import { useState, useEffect } from 'react';
import {
	getBgEmoticonsClass,
	getEmoticonsClass,
} from '../../../../../util/getEmoticonsClass';
import TooltipDescription from '../../../../Common/TooltipDescription';
import useUser from '../../../../../hooks/useUser';

const FormPrecall = ({ item = null, toggleDialog, refetchReservation }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.operation.formPrecall',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const user = useUser();
	const banner = useBanner();
	const [animo, setAnimo] = useState('');
	const [bgAnimo, setBgAnimo] = useState('');
	console.log(item);

	const { data: emotionsClientsTypeOpt } = useQuery(
		['getListEmotionsClients'],
		async () => {
			const response = await getListEmotionsClients();
			return response;
		},
		{
			select: (response) =>
				response.data.list
					.map((it) => ({
						key: parseInt(it.key),
						value: it.id,
						label: it.mood,
						labelEn: it.moodEng,
					}))
					.sort((a, b) => a.key - b.key),
		}
	);

	//create precall
	const { mutate: createItem, isLoading: isCreating } = useMutation({
		mutationFn: createPrecall,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(SAVE_SUCCESS));
			refetchReservation();
			toggleDialog();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	//update preCall
	const { mutate: updateItem, isLoading } = useMutation({
		mutationFn: updatePrecall,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			refetchReservation();
			toggleDialog();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			note: item?.note ?? '',
			note1: item?.note1 ?? '',
			qualify: item?.qualify ?? '',
			specialRequest: item?.specialRequest ?? '',
			moodId: item?.status?.id ?? '',
			user: user?.usuario ?? '',
		},
		validationSchema: Yup.object({
			qualify: Yup.string().required(tMessage(FIELD_REQUIRED)),
		}),
		onSubmit: async (values) => {
			//submit request
			const data = {};
			Object.entries(removetEmptyObject(values)).forEach((entry) => {
				const [key, value] = entry;
				data[key] = value;
			});
			console.log(data);
			if (item.id) {
				updateItem({
					id: item.id,
					body: data,
				});
			} else {
				createItem({ ...data, reservationId: item.reservationId });
			}
		},
	});

	useEffect(() => {
		const emot = emotionsClientsTypeOpt?.find(
			(it) => it.value === formik?.values?.moodId
		);
		setAnimo(emot?.label ?? '');
		setBgAnimo(getBgEmoticonsClass(emot?.key ?? ''));
	}, [emotionsClientsTypeOpt, formik?.values?.moodId]);

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col xs="12" md="4">
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="qualify">
							{t('qualification')}
						</Label>
						<Select
							id="hotel"
							className="mb-0"
							value={
								formik.values.qualify
									? {
											value: formik.values.qualify,
											label: formik.values.qualify,
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue(
									'qualify',
									value?.value ?? ''
								);
							}}
							options={qualifyOpt}
							placeholder={tMessage(SELECT_OPTION)}
						/>
						{formik.errors.qualify && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.qualify}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs="12" md="4">
					<div className="mb-2">
						<div className="d-flex flex-column">
							<div>
								<Label
									className="form-label mb-0"
									htmlFor="tipo"
								>
									{t('mood')}
									{animo ? (
										<span
											style={{
												backgroundColor: bgAnimo,
												color: '#fff',
											}}
											className="px-2 py-1 rounded-pill ms-2"
										>
											{animo}
										</span>
									) : (
										''
									)}
								</Label>
							</div>
							<div>
								<ul className="list-inline hstack gap-0 mb-0">
									{emotionsClientsTypeOpt?.map((emot) => (
										<li
											key={`emot-${emot.key}-${emot.key}`}
											className="list-inline-item"
											id={`emot-${emot.key}-${emot.key}`}
										>
											<i
												className={`fs-3 cursor-pointer ${getEmoticonsClass(
													emot.key
												)}`}
												onClick={() => {
													setAnimo(emot.label);
													formik.setFieldValue(
														'moodId',
														emot.value
													);
													setBgAnimo(
														getBgEmoticonsClass(
															emot.key
														)
													);
												}}
											/>
											<TooltipDescription
												text={emot.label}
												id={`emot-${emot.key}-${emot.key}`}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<Row className="mb-md-3 mb-2">
				<Col xs="12" md="4">
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="note">
							{t('information')}
						</Label>
						<textarea
							id="note"
							name="note"
							className={`form-control`}
							value={formik.values.note}
							onChange={formik.handleChange}
							rows={4}
						/>
					</div>
				</Col>
				<Col xs="12" md="4">
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="note1">
							{t('observations')}
						</Label>
						<textarea
							id="note1"
							name="note1"
							className={`form-control`}
							value={formik.values.note1}
							onChange={formik.handleChange}
							rows={4}
						/>
					</div>
				</Col>
				<Col xs="12" md="4">
					<div className="mb-2">
						<Label
							className="form-label mb-0"
							htmlFor="specialRequest"
						>
							{t('specialRequest')}
						</Label>
						<textarea
							id="specialRequest"
							name="specialRequest"
							className={`form-control`}
							value={formik.values.specialRequest}
							onChange={formik.handleChange}
							rows={4}
						/>
					</div>
				</Col>
			</Row>

			{(!isLoading || !isCreating) && (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleDialog ? toggleDialog : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}

			{(isLoading || isCreating) && (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			)}
		</Form>
	);
};

export default FormPrecall;
