import { useNavigate } from 'react-router-dom';

const LinkCard = ({ label, iconClass, to }) => {
	const navigate = useNavigate();
	return (
		<div
			className="d-flex flex-column align-items-center cursor-pointer"
			onClick={() => navigate(to)}
			style={{
				width: '94px',
			}}
		>
			<div className="card-link">
				<i className={`${iconClass} fs-1`} />
			</div>
			<span className="card-link-title" id={to} title={label}>
				{label}
			</span>
		</div>
	);
};

export default LinkCard;
